import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const useRouterScrollToTop = () => {
  const router = useRouter();
  useEffect(() => {
    const handle = () => {
      if (document) {
        const elements = document.getElementsByClassName('top-of-dashboard');
        if (elements.length > 0) {
          setTimeout(() => {
            elements[0].scrollIntoView({ behavior: 'smooth' });
          }, 300);
        }
      }
    };
    router.events.on('routeChangeComplete', handle);
    return () => {
      router.events.off('routeChangeComplete', () => {});
    };
  }, [router]);
};

export default useRouterScrollToTop;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TSidebarKey } from '../types/app';
import { RootState } from './store';

type TState = {
  activeKey: TSidebarKey
}
const initialState: TState = {
  activeKey: 'dashboard',
};

const slice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    activeKeyChanged: (state, { payload }: PayloadAction<{ key: TSidebarKey }>) => {
      state.activeKey = payload.key;
    },
  },
});

export const activeKey = (state: RootState) => state.entities.sidebar.activeKey;

export const { activeKeyChanged } = slice.actions;

export default slice.reducer;
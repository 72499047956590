import { combineReducers } from '@reduxjs/toolkit';
import { default as modalsReducer } from './modals';
import { default as sidebarReducer } from './sidebar';
import { default as headerReducer } from './header';

const entities = combineReducers({
  modals: modalsReducer,
  sidebar: sidebarReducer,
  header: headerReducer,
});
export default entities;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TAuth, TRole } from '../types/app';
import { RootState } from './store';

const initialState: TAuth = {
  register: {},
};

const slice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    roleSelected: (state, { type, payload }: PayloadAction<TRole>) => {
      state.register.role = payload;
    },
  },
});

const reducer = slice.reducer;
export const { roleSelected } = slice.actions;
export default reducer;

export const getSelectedRole = (state: RootState) => state.auth.register.role;

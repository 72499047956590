import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

type TState = {
  title: string;
  userImage?: string;
};
const initialState: TState = {
  title: 'RETM',
  userImage: undefined,
};

const slice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    titleChanged: (state, { payload }: PayloadAction<{ title: string }>) => {
      state.title = payload.title;
    },
    userImageChanged: (
      state,
      { payload }: PayloadAction<{ image?: string }>
    ) => {
      state.userImage = payload.image;
    },
  },
});

export const title = (state: RootState) => state.entities.header.title;
export const userImage = (state: RootState) => state.entities.header.userImage;

export const { titleChanged, userImageChanged } = slice.actions;

export default slice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { TPropertyType, TEditPropertyFrom } from '../types/app';

type TStartNewPropertyModal = {
  open: boolean;
  parentId: number | '~';
  portfolioId: number | '~';
  units: 'all' | TPropertyType[];
  from: TEditPropertyFrom;
};

type TAssignManagerModal = {
  open: boolean;
  id: number;
  assignTo: 'portfolio' | 'property';
};

interface TModalsStore {
  supportModal: {
    open: boolean;
  };
  alertModal: {
    open: boolean;
    message: string;
    label: string;
  };
  confirmModal: {
    open: boolean;
    message: string;
    cancelLabel: string;
    confirmLabel: string;
  };
  addBeneficiaryModal: {
    open: boolean;
  };
  startNewPropertyModal: TStartNewPropertyModal;
  changePasswordModal: {
    open: boolean;
  };
  createInvoiceModal: {
    open: boolean;
    invoiceType?: 'invoice' | 'expense';
  };
  addManagerModal: {
    open: boolean;
  };
  addPortfolioModal: {
    open: boolean;
  };
  assignManagerModal: TAssignManagerModal;
  phoneVerifyModal: {
    open: boolean;
  };
  upgradeAccountModal: {
    open: boolean;
  };
}

const initialState: TModalsStore = {
  supportModal: {
    open: false,
  },
  alertModal: {
    open: false,
    message: '',
    label: '',
  },
  confirmModal: {
    open: false,
    message: '',
    cancelLabel: '',
    confirmLabel: '',
  },
  addBeneficiaryModal: {
    open: false,
  },
  startNewPropertyModal: {
    open: false,
    parentId: '~',
    portfolioId: '~',
    units: 'all',
    from: 'properties',
  },
  changePasswordModal: {
    open: false,
  },
  createInvoiceModal: {
    open: false,
    invoiceType: undefined,
  },
  addManagerModal: {
    open: false,
  },
  addPortfolioModal: {
    open: false,
  },
  assignManagerModal: {
    open: false,
    id: -1,
    assignTo: 'portfolio',
  },
  phoneVerifyModal: {
    open: false,
  },
  upgradeAccountModal: {
    open: false,
  },
};

const slice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    supportModalOpened: (
      state,
      { payload }: PayloadAction<{ open: boolean }>
    ) => {
      state.supportModal = payload;
    },
    addBeneficiaryModalOpened: (
      state,
      { payload }: PayloadAction<{ open: boolean }>
    ) => {
      state.addBeneficiaryModal = payload;
    },
    alertModalOpened: (
      state,
      { payload }: PayloadAction<{ message: string; label: string }>
    ) => {
      state.alertModal = {
        ...payload,
        open: true,
      };
    },
    alertModalClosed: (state, {}: PayloadAction<{}>) => {
      state.alertModal.open = false;
    },
    confirmModalOpened: (
      state,
      {
        payload,
      }: PayloadAction<{
        message: string;
        cancelLabel: string;
        confirmLabel: string;
      }>
    ) => {
      state.confirmModal = {
        ...payload,
        open: true,
      };
    },
    confirmModalClosed: (state, {}: PayloadAction<{}>) => {
      state.confirmModal.open = false;
    },
    startNewPropertyModalOpened: (
      state,
      { payload }: PayloadAction<TStartNewPropertyModal>
    ) => {
      state.startNewPropertyModal = payload;
    },
    changePasswordOpened: (
      state,
      { payload }: PayloadAction<{ open: boolean }>
    ) => {
      state.changePasswordModal = payload;
    },
    createInvoiceModalOpened: (
      state,
      {
        payload,
      }: PayloadAction<{ open: boolean; invoiceType?: 'invoice' | 'expense' }>
    ) => {
      state.createInvoiceModal = payload;
    },
    addManagerModalOpened: (
      state,
      { payload }: PayloadAction<{ open: boolean }>
    ) => {
      state.addManagerModal = payload;
    },
    addPortfolioModalOpened: (
      state,
      { payload }: PayloadAction<{ open: boolean }>
    ) => {
      state.addPortfolioModal = payload;
    },
    assignManagerModalOpened: (
      state,
      { payload }: PayloadAction<TAssignManagerModal>
    ) => {
      state.assignManagerModal = payload;
    },
    phoneVerifyModalOpened: (
      state,
      { payload }: PayloadAction<{ open: boolean }>
    ) => {
      state.phoneVerifyModal = payload;
    },
    upgradeAccountModalOpened: (
      state,
      { payload }: PayloadAction<{ open: boolean }>
    ) => {
      state.upgradeAccountModal = payload;
    },
  },
});

export const isSupportModalOpened = (state: RootState) =>
  state.entities.modals.supportModal.open;
export const isBeneficiaryModalOpened = (state: RootState) =>
  state.entities.modals.addBeneficiaryModal.open;
export const alertModal = (state: RootState) =>
  state.entities.modals.alertModal;
export const confirmModal = (state: RootState) =>
  state.entities.modals.confirmModal;
export const isChangePasswordOpened = (state: RootState) =>
  state.entities.modals.changePasswordModal.open;
export const startNewProperty = (state: RootState) =>
  state.entities.modals.startNewPropertyModal;
export const createInvoiceModal = (state: RootState) =>
  state.entities.modals.createInvoiceModal;
export const isAddManagerModalOpened = (state: RootState) =>
  state.entities.modals.addManagerModal.open;
export const isAddPortfolioModalOpened = (state: RootState) =>
  state.entities.modals.addPortfolioModal.open;

export const assignManagerModal = (state: RootState) =>
  state.entities.modals.assignManagerModal;

export const isphoneVerifyModalOpened = (state: RootState) =>
  state.entities.modals.phoneVerifyModal.open;

export const isUpgradeAccountModalOpened = (state: RootState) =>
  state.entities.modals.upgradeAccountModal.open;

export const {
  supportModalOpened,
  alertModalOpened,
  alertModalClosed,
  confirmModalOpened,
  confirmModalClosed,
  addBeneficiaryModalOpened,
  changePasswordOpened,
  startNewPropertyModalOpened,
  createInvoiceModalOpened,
  addManagerModalOpened,
  addPortfolioModalOpened,
  assignManagerModalOpened,
  phoneVerifyModalOpened,
  upgradeAccountModalOpened,
} = slice.actions;

export default slice.reducer;

import * as Sentry from '@sentry/nextjs';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/tracing';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const isProduction = ['production', 'preview'].includes(
  process.env.NODE_ENV?.toLowerCase() || ''
);
if (isProduction && SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 1,
    integrations: [
      new CaptureConsoleIntegration({
        levels: ['info'],
      }),
      new BrowserTracing({
        tracingOrigins: ['staging.retmco.com', 'retmco.com'],
      }),
    ],
  });
}
